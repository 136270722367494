import request from "../util/request"
import global from "../global"
import {createModel} from "@rematch/core"
import {RootModel} from "./index"

/**
 * 会话
 */
export const session = createModel<RootModel>()({
  state: {
    isLogin: global.getSessionKey() !== null,
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 登录
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async login(payload, state): Promise<any> {
      let res = await request.post("/client/wemp/login", payload)
      if (res.code === 0) {
        global.saveSessionKey(res.data.sessionKey, res.data.expireTime)//将登录信息保存到本地存储
      } else {
        console.log(res.msg);
      }
      return res
    },
  }),
})
