import React, {useCallback, useEffect} from 'react';
import './MobileBind.less';
import {Button, Form, Input, Toast, Image} from "antd-mobile";
import {useDispatch, useSelector} from "react-redux";
import banner from "../../images/logo.png";
import global from "../../global";
import { v4 as uuidv4 } from "uuid"
import {PageFooter} from "../util";

/**
 * 输入手机号码表单
 * @constructor
 */
const MobileForm = () => {
  const dispatch = useDispatch();
  const baseCodeUrl = global.getApiServer() + '/pub/v1/session/captcha';
  let [verCodeUrl, setVerCodeUrl] = React.useState<string>("")

  const onImgCode = useCallback(() => {
    setVerCodeUrl(baseCodeUrl + "?u=" + uuidv4())
  }, [baseCodeUrl])

  useEffect(() => {
    onImgCode()
  }, [onImgCode]);

  const onFinish = (values: any) => {
    console.log(values);
    dispatch.user.verifyCode(values).then((res: { code: number; msg: any; data: number }) => {
      if (res.code === 0) {
        dispatch.user.setData({smsId: res.data, mobile: values.mobile}) //保存短信id
        Toast.show({
          icon: 'success',
          content: '验证码发送成功',
        })
      } else {
        Toast.show({
          icon: 'fail',
          content: res.msg,
        })
      }
    });
  }

  return (
    <Form layout='vertical' footer={
      <Button block type='submit' color='primary' size='large'>
        发送验证码
      </Button>
    }
          onFinish={onFinish}>
      <Form.Item label='手机号码' name='mobile' rules={[{required: true, message: '不能为空'}]}>
        <Input type="tel" placeholder='请输入手机号码' clearable autoFocus={true}/>
      </Form.Item>
      <Form.Item label="验证码" name="imgCode" rules={[{ required: true, message: "请输入验证码!" }]}>
        <Input />
      </Form.Item>
      <div style={{marginLeft: 100, width: 200}}>
        <Image src={verCodeUrl} onClick={onImgCode}/>
      </div>
    </Form>
  )
}

/**
 * 绑定表单
 * @constructor
 */
const BindForm = () => {
  const user = useSelector((state: any) => state.user)
  const dispatch = useDispatch();
  const onFinish = (values: any) => {

    dispatch.user.bind(values).then((res: { code: number; msg: any; data: boolean }) => {
      if (res.code === 0) {
        let info = {...dispatch.user.info, mobile: user.mobile}
        dispatch.user.setData({bind: res.data, info}) //保存绑定状态
        Toast.show({
          icon: 'success',
          content: '绑定成功',
        })
        //跳转界面输入验证码绑定
      } else {
        Toast.show({
          icon: 'fail',
          content: res.msg,
        })
      }
    });
  }

  const customGetValueFromEvent = (e: any) => {
    if (e.length > 6) {
      return e.slice(0, 6);
    }
    return e;
  };

  return (
    <Form layout='vertical' footer={
      <Button block type='submit' color='primary' size='large'>
        绑定
      </Button>
    }
          onFinish={onFinish}>
      <Form.Item label='验证码' name='code' getValueFromEvent={customGetValueFromEvent}
                 rules={[{required: true, message: '不能为空'}]}>
        <Input type="number" placeholder='请输入验证码' clearable autoFocus={true}/>
      </Form.Item>
    </Form>
  )
}

const Success = () => {
  let user = useSelector((state: any) => state.user)
  let dispatch = useDispatch();
  const onFinish = (values: any) => {
    dispatch.user.setData({bind: false, smsId: 0, mobile: ''})
  }
  const onClose = () => {
    WeixinJSBridge.call('closeWindow');
  }
  return (
    <Form layout='vertical' footer={
      <>
        <Button block type='submit' size='large' color='danger'>
          重新绑定
        </Button>
        <Button style={{marginTop: 20}} block color='primary' size='large' onClick={onClose}>关闭</Button>
      </>
    }
          onFinish={onFinish} initialValues={{mobile: user.info.mobile}}>
      <Form.Item label='已经绑定手机' name='mobile'>
        <Input type="number" readOnly={true}/>
      </Form.Item>
    </Form>
  )
}

const MobileBind: React.FC = () => {
  const session = useSelector((state: any) => state.session);
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  /**
   * 启动
   */
  useEffect(() => {
    document.title = '绑定手机号码'

    //读取用户信息
    if (global.getSessionKey() !== null) {
      dispatch.user.getInfo();
    }

  }, [session.isLogin, dispatch.user]);

  return (
    <div className={'MobileBind'}>
      <div>
        <img src={banner} alt="banner" style={{width: '100%'}}/>
      </div>
      <div className='bind'>
        <h3>绑定手机号码</h3>
        {
          user.bind ? <Success/> :
            user.smsId === 0 ? <MobileForm/> : <BindForm/>
        }
        <PageFooter/>
      </div>
    </div>
  )
}

export default MobileBind;