import global from "../global"

/**
 * 请求
 *
 * @type {{post(*, *): Promise<any>, upload(*, *): Promise<void>, get(*): Promise<*>}}
 * @author winixi@qq.com
 */
const request = {
  /**
   * get
   *
   * @param apiUri
   * @param isString
   * @param isConfigApiServer
   * @returns {Promise<any>}
   */
  async get(apiUri: string, isString: boolean = false, isConfigApiServer: boolean = true): Promise<any> {
    let options: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Session-Key": global.getSessionKey(),
      } as HeadersInit,
    }
    let ApiServer: string = ""
    if (isConfigApiServer) ApiServer = global.getApiServer()
    let res: Response = await fetch(ApiServer + apiUri, options)
    if (isString) return res.text()
    return res.json()
  },

  /**
   * post
   *
   * @param apiUri
   * @param data
   * @param isString
   * @param isConfigApiServer
   */
  async post(apiUri: string, data: object, isString: boolean = false, isConfigApiServer: boolean = true): Promise<any> {
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Session-Key": global.getSessionKey(),
      } as HeadersInit,
      body: JSON.stringify(data),
    }
    let ApiServer: string = ""
    if (isConfigApiServer) ApiServer = global.getApiServer()
    let response: Response = await fetch(ApiServer + apiUri, options)
    if (isString) return response.text()
    return response.json()
  },

  /**
   * 修改
   *
   * @param apiUri
   * @param data
   * @param isString
   * @param isConfigApiServer
   */
  async put(apiUri: string, data: object, isString: boolean = false, isConfigApiServer: boolean = true): Promise<any> {
    let options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Session-Key": global.getSessionKey(),
      } as HeadersInit,
      body: JSON.stringify(data),
    }
    let ApiServer: string = ""
    if (isConfigApiServer) ApiServer = global.getApiServer()
    let response: Response = await fetch(ApiServer + apiUri, options)
    if (isString) return response.text()
    return response.json()
  },

  /**
   * 删除
   * @param apiUri
   * @param data
   * @param isString
   * @param isConfigApiServer
   */
  async delete(apiUri: string, isString: boolean = false, isConfigApiServer: boolean = true): Promise<any> {
    let options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Session-Key": global.getSessionKey(),
      } as HeadersInit,
    }
    let ApiServer: string = ""
    if (isConfigApiServer) ApiServer = global.getApiServer()
    let response: Response = await fetch(ApiServer + apiUri, options)
    if (isString) return response.text()
    return response.json()
  },

  /**
   * 上传文件
   *
   * @param apiUri
   * @param file
   * @param data
   * @returns {Promise<void>}
   */
  async upload(apiUri: string, file: File, data: any): Promise<any> {
    let formData: FormData = new FormData()
    formData.append("file", file)
    for (let key in data) {
      formData.append(key, data[key])
    }
    let options = {
      method: "POST",
      headers: {
        // 'Content-Type': 'multipart/form-data;charset=utf-8',
        "Session-Key": global.getSessionKey(),
      } as HeadersInit,
      body: formData,
    }
    let response: Response = await fetch(global.getApiServer() + apiUri, options)
    return response.json()
  },
}

export default request
