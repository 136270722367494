import React, {useEffect} from 'react';
import './ModuleAllow.less';
import {useNavigate, useParams} from "react-router";
import global from "../../global";
import {useDispatch, useSelector} from "react-redux";
import {Button, List, Result, Toast} from "antd-mobile";
import logo from "../../images/logo.png";
import {PageFooter} from "../util";
import {ListItem} from "antd-mobile/es/components/list/list-item";

const ModuleAllow: React.FC = () => {
  const {code} = useParams();
  const session = useSelector((state: any) => state.session);
  const qrCodeLogin = useSelector((state: any) => state.qrCodeLogin);
  const [loading, setLoading] = React.useState(true);
  const [res, setRes] = React.useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (global.getSessionKey() == null) return;
    if (code === undefined) return;

    //读取二维码信息
    dispatch.qrCodeLogin.setData({code});
    dispatch.qrCodeLogin.scan().then((res: any) => {
      setRes(res);
      setLoading(false);
    });

  }, [code, dispatch.qrCodeLogin, session.isLogin]);

  return (
    <div className='ModuleAllow'>
      {loading ? null :
        qrCodeLogin.success ? <SuccessFrame/> : <ScanFrame res={res}/>
      }
      <PageFooter/>
    </div>
  )
}

const ScanFrame: React.FC<any> = (props) => {
  return (
    <>
      {props.res.code === 0 && <AllowFrame/>}
      {props.res.code === 601 && <MobileFrame msg={props.res.msg}/>}
      {props.res.code === 602 && <StatusErrFrame msg={props.res.msg}/>}
    </>
  );
}

const SuccessFrame: React.FC = () => {
  const onClose = () => {
    WeixinJSBridge.call('closeWindow');
  }

  return (
    <div className={'success'}>
      <div className={'result'}>
        <Result
          status='success'
          title='操作成功'
          description='关闭手机界面，请在电脑端继续操作'
        />
      </div>
      <div className={'btn'}>
        <Button style={{width: '100%'}} block color='primary' size='large' onClick={onClose}>关闭</Button>
      </div>
    </div>
  )
}

const StatusErrFrame: React.FC<any> = (props) => {

  const onClose = () => {
    WeixinJSBridge.call('closeWindow');
  }

  return (
    <div className={'success'}>
      <div className={'result'}>
        <Result
          status='error'
          title='二维码错误'
          description={props.msg}
        />
      </div>
      <div className={'btn'}>
        <Button style={{width: '100%'}} block color='primary' size='large' onClick={onClose}>关闭</Button>
      </div>
    </div>
  );
}

const MobileFrame: React.FC<any> = (props) => {
  const navigate = useNavigate()

  return (
    <div className={'success'}>
      <div className={'result'}>
        <Result
          status='error'
          title='未绑定手机'
          description={props.msg}
        />
      </div>
      <div className={'btn'}>
        <Button style={{width: '100%'}} block color='primary' size='large' onClick={() => {
          navigate('/mobileBind')
        }}>去绑定手机</Button>
      </div>
    </div>
  );
}

const AllowFrame: React.FC = () => {
  const qrCodeLogin = useSelector((state: any) => state.qrCodeLogin);
  const dispatch = useDispatch();

  const onAllow = (companyId: number) => {
    let data = {
      code: qrCodeLogin.code,
      moduleType: qrCodeLogin.info.moduleType,
      companyId,
    }
    dispatch.qrCodeLogin.allow(data).then((res: any) => {
      if (res.code === 0) {
        dispatch.qrCodeLogin.setData({success: true});
      } else {
        Toast.show({
          icon: "fail",
          content: res.msg,
        });
      }
    });
  }

  const onClose = () => {
    WeixinJSBridge.call('closeWindow');
  }

  return (
    <div className={'allow'}>
      <div className={'logo'}>
        <img src={logo} alt="logo" style={{width: 150}}/>
      </div>
      {
        qrCodeLogin.info && (
          <div className='content'>
            <div className={'title'}>
              {
                qrCodeLogin.info.moduleType === 'MANAGER' && '商家后台'
              }
              {
                qrCodeLogin.info.moduleType === 'ADMIN' && '运营后台'
              }
            </div>
            <List header={'选择你要授权登录的单位'}>
              {
                qrCodeLogin.info.companies.length === 0 && (
                  <ListItem>无可用单位，未授权使用本系统</ListItem>
                )
              }
              {
                qrCodeLogin.info.companies.map((company: any, index: number) => {
                  return (
                    <ListItem key={index} onClick={() => onAllow(company.id)}>
                      {company.name}
                    </ListItem>
                  )
                })
              }
            </List>
          </div>
        )
      }
      {
        qrCodeLogin.info.companies.length === 0 && (
          <div className={'btn'}>
            <Button style={{width: '100%'}} block color='primary' size='large' onClick={onClose}>关闭</Button>
          </div>
        )
      }
    </div>
  )
}

export default ModuleAllow;