import React, {useEffect} from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Test from "./pages/test/Test";
import MobileBind from "./pages/mobileBind/MobileBind";
import ModuleAllow from "./pages/moduleAllow/ModuleAllow";
import global from "./global";
import {useDispatch, useSelector} from "react-redux";

/**
 * 私有组件
 * @param props
 * @constructor
 */
function Private(props: { content: any; }) {
  const session = useSelector((state: any) => state.session);
  const dispatch = useDispatch();
  const Content = props.content;

  if (session.isLogin) {
    console.log("找到本地sessionKey直接进入");
    return (<>{Content}</>);
  }

  //检查是否已经登录或已经有code了
  const code = new URLSearchParams(window.location.search).get('code');
  const state = new URLSearchParams(window.location.search).get('state');
  if (code != null && state === "wechat") {
    console.log("已经拿到code，去后台登录继续拿到sessionKey");
    dispatch.session.login({code, userType: 'WECHAT'}).then(() => {
      //登录成功后，清除url中的code，不能这样，因为我自己也要传参数
      //window.history.replaceState({}, '', window.location.href.split("?")[0]);
      console.log("登录成功");
      dispatch.session.setData({isLogin: true})//这个状态修改一定放后面
    });
    return (<>{Content}</>);
  }

  //最终发现没有登录或登录失效，组装微信授权地址，来个转世轮回
  let redirectUrl: string = encodeURIComponent(window.location.href);
  let appId: string = global.appId;
  window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appId + "&redirect_uri=" + redirectUrl + "&response_type=code&scope=snsapi_base&state=wechat#wechat_redirect";

  return (<section>跳转微信授权...</section>);
}

/**
 * 应用路由
 */
const AppRouter = () => {
  useEffect(() => {
    document.title = global.name;
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/test" element={<Test/>}/>
        <Route path="/moduleAllow/:code" element={<Private content={<ModuleAllow/>}/>}/>
        <Route path="/mobileBind" element={<Private content={<MobileBind/>}/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
