export interface UserInfo {
  nickName: string
  mobile: string
  name: string
  avatar: string
}

/**
 * 全局配置
 */
const global = {
  name: "握手",
  company: "上海顶讯网络科技有限公司",
  appId: "wx44b264e0a432c380",

  //版本
  version: "0.1",

  //环境，通过请求地址自动判断是否开发环境
  env: window.location.href.startsWith("http://localhost") ? "dev" : "pro",

  //接口服务器
  apiServers: [
    {
      env: "dev",
      url: "/dev-api",  //开发环境下使用代理
    },
    {
      env: "pro",
      url: "https://client.woshou.lianhezuqiu.cn/api",
    },
  ],

  //获取接口地址
  getApiServer(): string {
    let url: string = ""
    this.apiServers.forEach((item: any): void => {
      if (item.env === this.env) url = item.url
    })
    return url
  },

  //获取令牌
  getSessionKey(): string | null {
    //dev环境下不需要登录
    if (this.env === "dev") {
      return "b476bd9bb892dbaf5f1f47cf22b1e220f6d0edacee8d5c755c3d523ceab2d01e"
    }

    let sessionKey: string | null = localStorage.getItem("sessionKey");
    if (sessionKey === 'undefined' || sessionKey == null) {
      return null
    }

    let expireTime: string | null = localStorage.getItem("expireTime")
    if (expireTime != null && new Date().getTime() < Date.parse(expireTime.replace(/-/g, '/'))) {
      return sessionKey
    }

    return null
  },

  //保存令牌
  saveSessionKey(sessionKey: string, expireTime: string): void {
    localStorage.setItem("sessionKey", sessionKey)
    localStorage.setItem("expireTime", expireTime)
  },

  //清空令牌
  clearSessionKey(): void {
    localStorage.removeItem("expireTime")
    localStorage.removeItem("sessionKey")
  },

  //获取当前用户信息
  getUserInfo(): UserInfo | {} {
    let userInfo = localStorage.getItem("userInfo")
    if (userInfo != null && userInfo !== "") {
      return JSON.parse(userInfo)
    }
    return {}
  },

  //保存用户信息
  saveUserInfo(userInfo: {}): void {
    localStorage.setItem("userInfo", JSON.stringify(userInfo))
  },

  //获取文件地址
  getFileOpenUrl(data: any) {
    if (data !== undefined)
      return this.getApiServer() + '/pub/v1/file/open?fileKey=' + encodeURI(data.fileKey) + '&accessKey=' + data.accessKey
    return '';
  }
}

export default global
