import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const qrCodeLogin = createModel<RootModel>()({
  state: {
    // initial state
    code: '',
    info: null,
    success: false,
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      }
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 扫码
     *
     * @param payload
     * @param rootState
     */
    async scan(payload, rootState) {
      let code:string = rootState.qrCodeLogin.code;
      let res = await request.post('/client/v1/qrCodeLogin/scan', {code});
      if (res.code !== 0) {
        console.log(res.msg)
      } else {
        dispatch.qrCodeLogin.setData({info: res.data});
      }
      return res;
    },
    /**
     * 同意
     *
     * @param payload
     * @param rootState
     */
    async allow(payload, rootState) {
      let res = await request.post('/client/v1/qrCodeLogin/allow', payload);
      if (res.code !== 0) {
        console.log(res.msg)
      }
      return res;
    }
  }),
})