import {Models} from "@rematch/core"
import {session} from "./session";
import {user} from "./user";
import {weixin} from "./weixin";
import {qrCodeLogin} from "./qrCodeLogin";
import {test} from "./test"

export interface RootModel extends Models<RootModel> {
  qrCodeLogin: typeof qrCodeLogin
  session: typeof session
  test: typeof test
  user: typeof user
  weixin: typeof weixin
}

export const models: RootModel = {
  qrCodeLogin,
  session,
  test,
  user,
  weixin,
}
