import {Footer} from "antd-mobile";
import {FC} from "react";

/**
 * 页脚
 * @constructor
 */
export const PageFooter: FC = () => {
  return (
    <Footer content='@2023 woshou All rights reserved'></Footer>
  )
}